import request from '@src/plugins/request'

// 或招标相关
export const getBidList = function (data) {
    return request({
        url: `/tender/invitation/page`,
        method: 'POST',
        data,
      })
}
//获取公告分类
export const getChannelNameList = function (data) {
  return request({
      url: `/erp-zb-project-notice/getChannelNameList`,
      method: 'get',
      params:data,
    })
}
//获取列表
export const getlistPage = function (data) {
  return request({
      url: `/erp-zb-project-notice/listPageWithPrj`,
      method: 'POST',
      data,
    })
}
//入驻企业列表
export const getsettlePage = function (data) {
  return request({
      url: `/bxbh/enterprise/settle/page`,
      method: 'POST',
      data,
    })
}

//企业分值
export const getscorePage = function (data) {
  return request({
      url: `/en/score/new/page`,
      method: 'POST',
      data,
    })
}
//企业专业资质排行
//企业分值
export const enQualification = function (data) {
  return request({
      url: `/enQualification/sort/page`,
      method: 'POST',
      data,
    })
}
//企业业绩排行榜


export const getProjectRanking = function (data) {
  return request({
      url: `/enterprise/project/sort/count/page`,
      method: 'POST',
      data,
    })
}

//企业业绩金额排行榜


export const getPriceRanking = function (data) {
  return request({
      url: `/enterprise/project/sort/page`,
      method: 'POST',
      data,
    })
}

//获取行业资质

export const industryInformation = function (data) {
  return request({
      url: `/industryInformation/page`,
      method: 'POST',
      data,
    })
}
//获取资讯分类

export const industryInformationCalssList = function (data) {
  return request({
      url: `/industryInformation/industryInformationCalssList`,
      method: 'POST',
      data,
    })
}
//获取人员类别
export const getAppidIsTree = function (data) {
  return request({
      url: `/commonCategory/getAppidIsTree`,
      method: 'GET',
      params:data,
    })
}
// 获取企业资质类别1
export const getCommonQualificationTypes = function (data) {
  return request({
      url: "/common/qualification/type/cate1/list",
      method: 'get',
      params: data
  })
}
//组合查询

export const compositePage = function (data) {
  return request({
      url: "/dcEnterprise/composite/page",
      method: 'POST',
      data,
  })
}

//获取栏目
export const getCommonColumnList = function (data) {
  return request({
      url: `/common/column/list`,
      method: 'GET',
      params: data
  })
}
//获取栏目 带子项
export const getCommonColumnChild = function (data) {
  return request({
      url: `/common/column/only/list`,
      method: 'POST',
      data,
  })
}
//获取栏目 带子项
export const getQuater = function (data) {
  return request({
      url: `/en/score/more/quarter`,
      method: 'POST',
      data,
  })
}
//获取栏目 标签
export const getCommonTags = function (data) {
  return request({
      url: `/common/tags/page`,
      method: 'POST',
      data,
  })
}
//获取资讯详情
export const getNewsDetial = function (data) {
  return request({
      url: `/industryInformation/detail`,
      method: 'GET',
      params:data,
  })
}
//全国 
export const getAreaPerson = function (data) {
  return request({
      url: `/enterprise/person/area/page`,
      method: 'POST',
      data,
  })
}
//统计业绩数量
export const enTypeCount = function (data) {
  return request({
      url: `/qyzzpt/browse/enTypeCount`,
      method: 'POST',
      data,
  })
}

// 分类
export const getCatory = function (data) {
  return request({
    url: `/commonCategory/listTree`,
    method: 'POST',
    data,
  })
}

// 获取招标信息
export const getNotiesBid = function (data) {
  return request({
    url: `/erp/commonRes/listPage2`,
    method: 'POST',
    data,
  })
}


