<template>
    <div style="display: flex;align-items: center;justify-content: center;">
        <div class="left-line"></div>
        <div class="title-text">施工企业资质业绩查询系统</div>
        <div class="right-line"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

.title-text {
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
    margin: 0 40px;
}

.left-line {
    width: 100px;
    height: 3px;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
}

.right-line {
    width: 100px;
    height: 3px;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
}

</style>